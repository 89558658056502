.experince__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experince__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experince__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experince__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.detail_inside {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.inside-icon {
  margin-bottom: 10px;
  color: #4db5ff;
}

/* <<<<<< MEDIA QUESRIES(MEDIUM DEVICES) >>>>>>>*/
@media screen and (max-width: 1024px) {
  .experince__container {
    grid-template-columns: 1fr;
  }
  .experince__container > div{
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .details{
    padding: 1rem;
  }
}

/* <<<<<< MEDIA QUESRIES(SMALL DEVICES) >>>>>>>*/
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }
  .experince__container > div{
    width: 100%;
    padding: 2rem 1rem;
  }

  .details{
    padding: 1rem;
  }
}
